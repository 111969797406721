<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.email" @change="Search('search')" placeholder="反馈联系邮箱"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.type" clearable  @change="Search('search')" placeholder="反馈类型">
            <el-option
              v-for="item in feedtypelist"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.product_model_id" clearable  @change="Search('search')" placeholder="产品型号">
            <el-option
              v-for="item in modellist"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.client_brand_code" clearable  @change="Search('search')" placeholder="品牌">
            <el-option
              v-for="item in brandlist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.status" @change="Search('search')" clearable placeholder="反馈状态">
            <el-option label="未处理" :value="1"></el-option>
            <el-option label="处理中" :value="2"></el-option>
            <el-option label="已处理" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="date1"
            :clearable="false"
            @blur="focusEvent1"
            type="datetime"
            placeholder="用户反馈开始时间"
            :shortcuts="shortcuts"
            @change="selectTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="date2"
            :clearable="false"
            @blur="focusEvent2"
            type="datetime"
            placeholder="用户反馈结束时间"
            :shortcuts="shortcuts"
            @change="selectTime1"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div>
        <!-- <el-button type="primary" size="small" @click="addfeedType">新建</el-button> -->
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="反馈联系邮箱">
        <template v-slot="scope">
          <span>{{ scope.row.email }}</span>
        </template>
      </el-table-column>
      <el-table-column label="反馈类型">
        <template v-slot="scope">
          <span>{{ feedtypeIdName[scope.row.type] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品型号">
        <template v-slot="scope">
          <span>{{ modelIdName[scope.row.product_id] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="反馈内容">
        <template v-slot="scope">
          <span>{{ scope.row.content != 0 ? scope.row.content.substr(0, 10) : '' }}</span>
          <span v-if="scope.row.content.length > 10">…</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="反馈图片地址">
        <template v-slot="scope">
          <span>{{ scope.row.image }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="反馈状态">
        <template v-slot="scope">
          <span>{{ scope.row.status==1?'未处理':scope.row.status==2?'处理中':scope.row.status==3?'已处理':'未知' }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="反馈用户类型">
        <template v-slot="scope">
          <span>{{ scope.row.user_type }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="创建时间">
        <template v-slot="scope">
          <span>{{ changeTime(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="修改时间">
        <template v-slot="scope">
          <span>{{ changeTime(scope.row.update_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <router-link :to="'/iot/feedbackinfo/'+scope.row.id">
            <el-button type="info" size="small">详情</el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-button type="text" :disabled="predisabled" @click="prePage" size="mini">上一页</el-button>
      <el-button type="text" :disabled="nextdisabled" @click="nextPage" size="mini">下一页</el-button>
    </div>
    

  </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../util/util'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      shortcuts: [
        {
          text: '今天',
          value: new Date(),
        },
        {
          text: '昨天',
          value: () => {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            return date
          },
        },
      ],
      search: {
        last_id: '',
        action: 2,
        email: '',
        type: '',
        product_model_id: '',
        status: '',
        start_time: '',
        end_time: '',
        rows: 10,
        client_brand_code: ''
      },
      list: [],
      feedtypelist: [],
      modellist: [],
      modelIdName: {},
      feedtypeIdName:{},
      predisabled: false,
      nextdisabled: false,
      date1: '',
      date2: '',
      brandlist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetProList () {
      this.api.BrandList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.brandlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    focusEvent1 () {
      if(!this.date1) {
        this.search.start_time = ''
        this.Search()
      }
    },
    focusEvent2 () {
      if(!this.date2) {
        this.search.end_time = ''
        this.Search()
      }
    },
    selectTime () {
      if (this.date1) {
        if (this.search.start_time == this.date1.getTime()/1000) {
          return
        }
        this.search.start_time = this.date1.getTime()/1000
        this.Search(('search'))
      } else {
        this.search.start_time = ''
        this.Search(('search'))
      }
    },
    selectTime1 () {
      if (this.date2) {
        if (this.search.end_time == this.date2.getTime()/1000) {
          return
        }
        this.search.end_time = this.date2.getTime()/1000
        this.Search(('search'))
      } else {
        this.search.end_time = ''
        this.Search(('search'))
      }
    },
    prePage () {
      this.search.action = 1
      this.search.last_id = this.list[0].id
      this.Search('page')
    },
    nextPage () {
      this.search.action = 2
      this.search.last_id = this.list[this.list.length-1].id
      this.Search('page')
    },
    GetfeedType(){
      this.api.FeedTypeList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.feedtypelist = res.data.data.data
          res.data.data.data.forEach(item => {
            this.feedtypeIdName[item.id] = item.name
          })
          this.GetModelList()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetModelList () {
      this.api.ProductModelList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.modellist = res.data.data.data
          res.data.data.data.forEach(item => {
            this.modelIdName[item.id]=item.name
          })
          this.GetProList()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Search(item){
      console.log('事件源',item)
      if(item == 'search') {
        this.search.last_id = ''
      }
      if (this.search.start_time !== '' && this.search.start_time == this.search.end_time) {
        this.$message.error('开始时间不能等于结束时间!')
        this.list = []
        return
      }
      this.api.FeedBackListList(this.search).then(res => {
        if (res.data.code == 200) {
          if(!res.data.data && item == 'search') {
            this.list = []
          }
          if (res.data.data.data) {
            if (this.search.action == 1) {
              this.nextdisabled = false
            } else {
              this.predisabled = false
            }
            this.list = res.data.data.data
          } else {
            if(item == 'search') {
              this.list = []
            } else if(item == 'page') {
              if (this.search.action == 1) { 
                this.predisabled = true
              } else {
                this.nextdisabled = true
              }
            }
          }
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    changeTime (item) {
      return util.dateFormat(item)
    }
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () {
    this.GetfeedType()
  }
}
</script>
<style>
.el-picker-panel__footer {
  display: none
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.page {
  float: right;
  margin-top: 10px
}
</style>
